import * as React from 'react';
import useTranslate from 'translations/translation-utils';
import { Button } from './Button';

export interface IProps {
    selectedValue?: boolean;
    toggleFor: string;
    onChange?: (value?: boolean) => void;
    isDisabled?: boolean;
}

export const TriStateToggle: React.FC<IProps> = ({
    selectedValue,
    onChange,
    isDisabled
}) => {
    const translate = useTranslate();
    const buttonIsDisabled = isDisabled && isDisabled === true;
    const buttonsClassName =
        selectedValue === null ? 'na' : selectedValue === true ? 'yes' : 'no';

    const onButtonClick = (value?: boolean) => {
        if (buttonIsDisabled) {
            return null;
        }

        let result: boolean = value;
        if (selectedValue !== null && value === selectedValue) {
            result = !selectedValue;
        }

        onChange(result);
    };

    return (
        <div className={`buttons buttons-tristate has-addons ${buttonsClassName}`}>
            <Button
                className={`button button-no${buttonIsDisabled ? ' button-no-disabled' : ''}`}
                onClick={() => onButtonClick(false)}
            >
                <span className='button-no-content'>
                    {translate('Globals.Label.Yes').toUpperCase()}
                </span>
            </Button>
            <button
                className={`button button-na`}
                onClick={() => onButtonClick(null)}
            >
            </button>
            <Button
                className={`button button-yes${buttonIsDisabled ? ' button-yes-disabled' : ''}`}
                onClick={() => onButtonClick(true)}
            >
                <span className='button-yes-content'>
                    {translate('Globals.Label.No').toUpperCase()}
                </span>
            </Button>
        </div>
    );
};
