import { IKeyValue } from 'models/key-value';
import { SiteFeatures } from 'actions/site-feature';

export type QuantumClaim =
    | 'ShiftHandover'
    | 'RiskClientFacingUpdate'
    | 'RiskCreate'
    | 'RiskAddAction'
    | 'RiskAddApprover'
    | 'RiskAutoFieldUpdate'
    | 'RiskUpdate'
    | 'RiskView'
    | 'RiskDelete'
    | 'ActionAutoFieldUpdate'
    | 'ActionView'
    | 'ActionCreate'
    | 'ActionUpdate'
    | 'ActionDelete'
    | 'AllAttachmentsUpload'
    | 'SiteView'
    | 'SiteCreate'
    | 'SiteDelete'
    | 'SiteUpdate'
    | 'ClientView'
    | 'ClientCreate'
    | 'ClientDelete'
    | 'ClientUpdate'
    | 'PeopleCBREEdit'
    | 'PeopleCBREView'
    | 'PeopleCBREDelete'
    | 'PeopleCBRECreate'
    | 'BuildingsDelete'
    | 'MyDataPropertyMappingToFacility'
    | 'RiskQuestionsOverviewUpdate'
    | 'ProcessUpdate'
    | 'ManageRoles'
    | 'AllAttachmentsDelete'
    | 'CermAssessmentExport'
    | 'SiteFeatures'
    | 'ManagePeople'
    | 'SendManualNotifications'
    | 'SiteExport'
    | 'SiteRolesEdit'
    | 'CriticalSystems'
    | 'CDNAUpdate'
    | 'CDNAView'
    | 'RiskProfileQuestionnaire'
    | 'RiskProfileMasterQuestionnaire'
    | 'CompetencyDNAProcess'
    | 'AllCommentUpdate'
    | 'ClientRiskRegister'
    | 'GlobalOverview'
    | 'CermAssessmentExpertReviewCreate'
    | 'GlobalMockDrills'
    | 'GlobalMockDrillsScenarioDelete'
    | 'CermAssessmentFinishExpertReviewNotification'
    | 'CermAssessmentApplicabilityUpdate'
    | 'CermAssessmentOutcomeUpdate'
    | 'CermAssessmentApplicabilityAccountLevelUpdate'
    | 'CDNAReportAllProfessionalsExport'
    | 'CermProcessOwnersDownloadAccessibility'
    | 'MockDrillsReadWrite'
    | 'MockDrillsLocalScenarioDelete'
    | 'MockDrillsLocalReportDelete'
    | 'MockDrillsParticipants'
    | 'RomonetEngineeringReportAccess'
    | 'DemoSite'
    | 'UploadUser'
    | 'IncidentCreate'
    | 'IncidentUpdate'
    | 'IncidentArchive'
    | 'IncidentView'
    | 'EditLocalMockDrillReportTitle'
    | 'CermAssessmentExpertReviewFinish'
    | 'ShiftHandoverRead'
    | 'ShiftHandoverDelete'
    | 'OpportunityQuestionnaireUpdate'
    | 'IAuditorClientTokenUpdate'
    | 'IAuditorView'
    | 'CermAssessmentUpdate'
    | 'SitesAddClientProxy'
    | 'ProcessArchive'
    | 'CDNADcProfessionalsApplicability'
    | 'RiskMacroLevelUpdate'
    | 'RiskReset'
    | 'OperatingPlatformUpdate'
    | 'OperatingPlatformDelete'
    | 'GlobalCompetencyDNAProcessesCreate'
    | 'GlobalCompetencyDNAProcessesUpdate'
    | 'GlobalCompetencyDNAProcessesDelete'
    | 'GlobalCompetencyDNAProcessesView'
    ;

export const checkPermission = (
    permission: QuantumClaim,
    permissions: Array<IKeyValue<string>>
) => {
    const permissionsToCheck = permissions.find((c) => c.key === permission);
    if (permissionsToCheck !== undefined) {
        return true;
    }
    return false;
};

export const checkSitePermission = (
    siteId: string,
    permission: string,
    permissions: Array<IKeyValue<string>>
) => {
    const permissionsToCheck = permissions.find((c) => c.key === siteId && c.value === permission);
    if (permissionsToCheck !== undefined) {
        return true;
    }
    return false;
};

export const checkPermissions = (permission: string[], permissions: Array<IKeyValue<string>>) => {
    return permissions.some((c) => permission.includes(c.key));
};

export const checkSiteFeature = (feature: string, features: string[]) => {
    return features.includes(feature);
};

export const checkIfAnySiteFeature = (requiredFeatures: string[], currentFeatures: string[]) => {
    return requiredFeatures.some((requiredFeature) =>
        currentFeatures.some((currentFeature) => currentFeature === requiredFeature)
    );
};

export const checkSiteHasFeature = (
    siteId: string,
    feature: SiteFeatures,
    lookupSitesFeatures: Array<IKeyValue<string[]>>
) => {
    if (!lookupSitesFeatures || lookupSitesFeatures.length < 1 || !siteId) {
        return false;
    }

    return lookupSitesFeatures.some(
        (siteFeature) => siteFeature.key === siteId && siteFeature.value.some((c) => c === feature)
    );
};

export const checkSiteHasFeatures = (
    siteId: string,
    feature: SiteFeatures[],
    lookupSitesFeatures: Array<IKeyValue<string[]>>
) => {
    if (!lookupSitesFeatures || lookupSitesFeatures.length < 1 || !siteId) {
        return false;
    }

    let isValid = false;
    feature.forEach((element) => {
        if (
            lookupSitesFeatures.some(
                (siteFeature) =>
                    siteFeature.key === siteId && siteFeature.value.some((c) => c === element)
            )
        ) {
            isValid = true;
        }
    });
    return isValid;
};

export const checkRole = (permission: string, permissions: Array<IKeyValue<string>>) => {
    const permissionsToCheck = permissions.find(
        (c) =>
            c.key === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' &&
            c.value === permission
    );
    if (permissionsToCheck !== undefined) {
        return true;
    }
    return false;
};

export const checkRoles = (roles: string[], permissions: Array<IKeyValue<string>>) => {
    return permissions.some(
        (c) =>
            c.key === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' &&
            roles.includes(c.value)
    );
};
