import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Form, FormikProps } from 'formik';
import { isNil } from 'lodash';
import moment from 'moment';
import { isNumber } from 'util';
import { getLatestQuarter } from 'business-area-functions/pue';
import { CustomSelect } from 'components/CustomSelect';
import EnergyBillForm from 'components/energy-bill-form/energy-bill-form';
import { FeatureToggle } from 'components/feature-toggle/feature-toggle';
import { Control, Field, Help, Input, Radio } from 'components/form';
import { ArticlePanel } from 'components/panel';
import { Select as FormSelect } from 'components/select';
import Select from 'react-select';
import { BulmaColor } from 'enums/BulmaColor';
import { BulmaSize } from 'enums/BulmaSize';
import { enumEnergyBillPeriods, IBuilding } from 'models/building';
import { IEnergyBill } from 'models/energy-bill';
import { IKeyValue } from 'models/key-value';
import { ISiteProfile, ImaxMinValues } from 'models/site-profile';
import { BuildingArchiveConfirmation } from 'routes/upsert-site/BuildingArchiveConfirmation';
import { IUpsertSitePageRouteParams } from 'routes/upsert-site/views/upsert-site-page';
import { formikFieldCssByState, getKeyed, scrollToFirstValidationError } from 'utils/form-utils';
import { newGuid } from 'utils/id-utils';
import { checkPermission } from 'utils/permission-utils';
import {
    RouteUrlBuilding,
    RouteUrlEnergyBill,
    RouteUrlQuestionnaire,
    RouteUrlSiteSummary,
} from '../../routes';
import { BarChartWithSelect } from './bar-chart-with-select';
import { BillingTable } from './billing-table';
import './building-form.scss';
import { LineChart } from './linechart';
import { Button } from 'components/v2/components';
import { SiteFeatures } from 'actions/site-feature';
import { IFacility } from 'models/facility-model';
import appConfig from 'helpers/config-helper';
import useTranslate from 'translations/translation-utils';
import { ICommonLookupModel } from 'models/common-lookup-model';
import { FloatingButton } from 'components/v2/components';
import { Privilege } from 'enums/Privilege';

export interface IProps extends RouteComponentProps<IUpsertSitePageRouteParams> {
    formikBag: FormikProps<Partial<ISiteProfile>>;
    onDeleteBuilding: (id: string, backLinkUrl: string) => void;
    loadFacility: () => void;
    onChangeRoot: (urlRoute: string) => void;
    onGetBenchmarkData: (siteId: string, buildingId: string) => void;
    onChangeMonth: (date: Date) => void;
    onSaveEnergyBill: (site: Partial<ISiteProfile>) => void;
    setErrorDialogVisible: (isDialogVisible: boolean) => void;
    shouldPublish: boolean;
    lookupCountries: Array<IKeyValue<string>>;
    lookupRegions: Array<IKeyValue<string>>;
    lookupCountryRegions: Array<IKeyValue<string>>;
    facilities: IFacility[];
    lookupFacilityTypes: Array<IKeyValue<string>>;
    lookupTierLevels: Array<IKeyValue<string>>;
    permissions: Array<IKeyValue<string>>;
    index?: number;
    isSubmitting?: boolean;
    pueProjections: any;
    shouldLoadBenchmarkData: boolean;
    setIsFacilityDialogVisible: (isDialogVisible: boolean) => void;
    facilityType: ICommonLookupModel[];
    addressList: Array<IKeyValue<string>>;
}

export const monthlyEnergyBillsOptions: Array<IKeyValue<string>> = [
    {
        key: 'Weeks',
        value: `week`,
    },
    {
        key: 'Months',
        value: `month`,
    },
];

export const BuildingForm: React.FC<IProps> = ({
    formikBag,
    match,
    onDeleteBuilding,
    loadFacility,
    shouldPublish,
    index,
    isSubmitting: isSubmitting,
    lookupCountries,
    lookupRegions,
    lookupCountryRegions,
    facilities,
    facilityType,
    lookupTierLevels,
    onChangeRoot,
    permissions,
    onSaveEnergyBill,
    onGetBenchmarkData,
    pueProjections,
    setErrorDialogVisible,
    setIsFacilityDialogVisible,
    addressList
}) => {
    const indexParam = Number(match.params && match.params.buildingIndex);
    const buildingIndex = indexParam >= 0 ? indexParam : index >= 0 ? index : 0;
    const currentBuilding = formikBag.values.buildings[buildingIndex];
    const isMyDataField = (): boolean => {
        return formikBag.values.buildings[buildingIndex].myDataPropertyId != null && formikBag.values.buildings[buildingIndex].myDataPropertyId != ''
    }

    React.useEffect(() => {
        if (
            formikBag.values.buildings &&
            formikBag.values.buildings.length > 0 &&
            formikBag.values.buildings[buildingIndex] &&
            formikBag.values.buildings[buildingIndex].id !== ''
        ) {
            if (formikBag.values.buildings[buildingIndex].questionnaireResult > 0) {
                onGetBenchmarkData(
                    formikBag.values.id,
                    formikBag.values.buildings[buildingIndex].id
                );
            }
        }

        if (formikBag.values.buildings.length > 0) {
            const index: number = formikBag.values.buildings.findIndex(b => b.isMasterAddress);
            if (addressList.length > 0 && index < 0) {
                formikBag.setFieldValue(`buildings[${addressList[0].key}].isMasterAddress`, true);
            }
        }

    }, [formikBag.values.buildings, match.params.buildingIndex]);

    React.useEffect(() => {
        if (!(facilities && facilities.length > 0)) {
            loadFacility();
            return;
        }
    }, [facilities]);

    const translate = useTranslate();

    const maxMinValues = {
        itPowerCapacity_MW: {
            max: 100,
            min: 0.5,
            maxMessage: translate('UpsertSite.BuildingForm.BillingTable.Message1'),
            minMessage: translate('UpsertSite.BuildingForm.BillingTable.Message2'),
        },
        raisedFloorSpace_SqFt: {
            max: 357000,
            min: 200,
            maxMessage: translate('UpsertSite.BuildingForm.BillingTable.Message1'),
            minMessage: translate('UpsertSite.BuildingForm.BillingTable.Message2'),
        },
        totalSpace_SqFt: {
            max: 481500,
            min: 400,
            maxMessage: translate('UpsertSite.BuildingForm.BillingTable.Message1'),
            minMessage: translate('UpsertSite.BuildingForm.BillingTable.Message2'),
        },
    };

    const yesNoOptions: Array<IKeyValue<string>> = [
        { key: 'Yes', value: 'Yes' },
        { key: 'No', value: 'No' },
    ];

    const unitOptions: Array<IKeyValue<string>> = [
        { key: 'm2', value: 'meter (m²)' },
        { key: 'ft2', value: 'foot (ft²)' },
    ];

    const doYouHaveGeneratorsOrDRupsOptions: Array<IKeyValue<string>> = [
        { key: 'Generators', value: 'Generators' },
        { key: 'DRUPS', value: 'DRUPS' },
    ];

    const environmentalMonitoringOptions: Array<IKeyValue<string>> = [
        { key: 'Nlyte', value: 'Nlyte' },
        { key: 'Synapsense', value: 'Synapsense' },
        { key: 'RFCode', value: 'RFCode' },
        { key: 'Ekkosense', value: 'Ekkosense' },
        { key: 'Vigilent', value: 'Vigilent' },
        { key: 'DCIM', value: 'DCIM' },
        { key: 'Other (Please Specify)', value: 'Other' },
    ];

    const coolingTypeOptions: Array<IKeyValue<string>> = [
        { key: 'DX', value: 'DX' },
        { key: 'Chilled Water', value: 'Chilled Water' },
        { key: 'Free Cooling', value: 'Free Cooling' },
        { key: 'Mixed', value: 'Mixed' },
    ];



    const onDeleteBuildingHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
        if (currentBuilding !== undefined && currentBuilding.id !== '') {
            onDeleteBuilding(currentBuilding.id, backLinkUrl);
        } else {
            const buildings = formikBag.values.buildings.filter((building) => {
                return building !== currentBuilding;
            });
            formikBag.setFieldValue(`buildings`, buildings);
            onChangeRoot(`${backLinkUrl}`);
        }
    };

    const onSubmitEnergyBill = async (energyBill: IEnergyBill) => {
        const buildings: IBuilding[] = formikBag.values.buildings.map((m) => {
            return {
                ...(m as IBuilding),
            };
        });
        if (!buildings[buildingIndex].energyBills) {
            buildings[buildingIndex].energyBills = [];
        }
        const energyBills = buildings[buildingIndex].energyBills.filter(
            (m) => m.id !== energyBill.id
        );
        const id = energyBill.id === '' || energyBill.id === undefined ? newGuid() : energyBill.id;
        energyBills.push({
            ...energyBill,
            id,
        });
        const sortedEnergyBills = energyBills.sort(
            (a: IEnergyBill, b: IEnergyBill) =>
                parseInt(moment(a.date).format('YYYYMMDD'), 10) -
                parseInt(moment(b.date).format('YYYYMMDD'), 10)
        );
        buildings[buildingIndex].energyBills = sortedEnergyBills;

        await formikBag.setFieldValue(`buildings[${buildingIndex}].energyBills`, sortedEnergyBills);

        const newValues = {
            ...formikBag.values,
            buildings,
        };

        onSaveEnergyBill(newValues);
        onChangeRoot(`${RouteUrlBuilding(match.params.id)}/${buildingIndex}#chart`);
    };

    const backLinkUrl =
        buildingIndex > 0
            ? `${RouteUrlBuilding(match.params.id)}/${buildingIndex - 1}`
            : `${RouteUrlSiteSummary(match.params.id)}`;

    const onPublish = () => {
        formikBag.validateForm().then((errors) => {
            if (!Object.keys(errors).some((field) => field.length > 0)) {
                const buildings: any[] = formikBag.values.buildings;

                buildings?.map(m => {
                    m.totalSiteCoolingPowerMw = !isNil(m.totalSiteCoolingPowerMw) ? m.totalSiteCoolingPowerMw.toString() : '';
                    m.raisedFloorSpace_SqFt = m.raisedFloorSpace_SqFt === '' ? null : m.raisedFloorSpace_SqFt;
                    m.totalSpace_SqFt = m.totalSpace_SqFt === '' ? null : m.totalSpace_SqFt;
                });
                formikBag.values.buildings = [...buildings];
                formikBag.submitForm();
            } else {
                setErrorDialogVisible(true)
            }
        });
    };

    const getCapacity = () => {
        return formikBag.values.buildings
            ? formikBag.values.buildings[buildingIndex].itPowerCapacity_MW
            : 0;
    };

    const onAddFacility = () => {
        if (checkFormikBagIsValid()) {
            if (formikBag.values.clientCode && (formikBag.values.clientCode != null && formikBag.values.clientCode != ""))
                setIsFacilityDialogVisible(true)
            else
                onChangeRoot(`${RouteUrlBuilding(match.params.id)}/${formikBag.values.buildings.length}`);
        } else {
            if (buildingIndex === formikBag.values.buildings.length - 1) {
                setErrorDialogVisible(true)
            }
            scrollToFirstValidationError();
            formikBag.submitForm();
        }
    };

    const onNavigateToNextFacility = () => {
        if (checkFormikBagIsValid()) {
            if (buildingIndex === formikBag.values.buildings.length - 1 && formikBag.values.clientCode && (formikBag.values.clientCode != null && formikBag.values.clientCode != ""))
                setIsFacilityDialogVisible(true)
            else
                onChangeRoot(`${RouteUrlBuilding(match.params.id)}/${buildingIndex + 1}`);
        } else {
            if (buildingIndex === formikBag.values.buildings.length - 1) {
                setErrorDialogVisible(true)
            }
            scrollToFirstValidationError();
            formikBag.submitForm();
        }
    };
    const onNavigateToQuestionnaire = () => {
        if (formikBag.values.buildings[buildingIndex].tier) {
            onChangeRoot(`${RouteUrlQuestionnaire(buildingIndex.toString(), match.params.id)}`);
        } else {
            alert(translate('UpsertSite.Views.BuildingForm.Message'));
        }
    };

    const onNavigateToEnergyBill = () => {
        if (checkFormikBagIsValid()) {
            onChangeRoot(
                `${RouteUrlEnergyBill(
                    buildingIndex.toString(),
                    currentBuilding.energyBills ? currentBuilding.energyBills.length : 0,
                    match.params.id
                )}`
            );
        } else {
            formikBag.submitForm();
            const element = document.getElementById(field('buildingName'));
            if (element !== null) {
                element.scrollIntoView();
            }
        }
    };

    const onNavigateToEnergyBillEdit = (energyBill: IEnergyBill) => {
        onChangeRoot(
            `${RouteUrlEnergyBill(
                buildingIndex.toString(),
                currentBuilding.energyBills.indexOf(energyBill),
                match.params.id
            )}`
        );
    };

    const onNavigateToEnergyBillDelete = (energyBill: IEnergyBill) => {
        const buildings: IBuilding[] = formikBag.values.buildings.map((m) => {
            return {
                ...(m as IBuilding),
            };
        });
        if (!buildings[buildingIndex].energyBills) {
            buildings[buildingIndex].energyBills = [];
        }
        buildings[buildingIndex].energyBills = buildings[buildingIndex].energyBills.filter(
            (f) => f !== energyBill
        );
        formikBag.setFieldValue(`buildings`, buildings);
        onChangeRoot(`${RouteUrlBuilding(match.params.id)}/${buildingIndex}`);
    };

    const checkFormikBagIsValid = () => {
        return formikBag.isValid || (value('id') && !formikBag.dirty);
    };

    const onNavigateToPreviousFacility = () => {
        if (checkFormikBagIsValid()) {
            onChangeRoot(`${backLinkUrl}`);
        } else {
            formikBag.submitForm();
        }
    };

    const getCountriesByRegions = () => {
        if (currentBuilding !== undefined && currentBuilding.region !== undefined) {
            const selectedRegion = currentBuilding.region;
            const countryCodesByRegion = lookupCountryRegions.filter((cr) => {
                return cr.value === selectedRegion;
            });
            const filteredCountries = lookupCountries.filter((item) => {
                return countryCodesByRegion.some((c) => c.key === item.key);
            });
            return filteredCountries;
        }
        return [];
    };

    const prefix = `buildings[${buildingIndex}]`;
    const field = (name: Extract<keyof IBuilding, string>) => `${prefix}.${name}`;

    function value<T extends Extract<keyof IBuilding, string>>(name: T) {
        const val = getKeyed(formikBag, 'values', field(name));
        return !isNil(val) ? val : '';
    }
    const getEnergyPeriod = (val: string) => {
        if (val === null || val === '') {
            formikBag.setFieldValue(`buildings[${buildingIndex}].energyBillPeriods`, 'month');
        }
        return val;
    };

    const getErrors = (name: Extract<keyof IBuilding, string>) =>
        getKeyed(formikBag, 'errors', field(name));
    const isTouched = (name: Extract<keyof IBuilding, string>) =>
        getKeyed(formikBag, 'touched', field(name));
    const getFieldCss = (name: Extract<keyof IBuilding, string>, otherCss?: string) =>
        formikFieldCssByState(Boolean(getErrors(name)), isTouched(name), otherCss);

    const getHelpByKey = (name: Extract<keyof IBuilding, string>) => (
        <Help isShown={formikBag.submitCount > 0 || isTouched(name)} bulmaColor={BulmaColor.Danger}>
            {getErrors(name)}
        </Help>
    );

    const getHelpWarningByKey = (name: Extract<keyof IBuilding, string>, maxMinValues: ImaxMinValues, value: any) => {
        return (
            !getErrors(name) ? <Help isShown={value !== '' && (isTouched(name) || value < maxMinValues.min || value > maxMinValues.max)} bulmaColor={BulmaColor.Warning} >
                {value < maxMinValues.min ?
                    maxMinValues.minMessage :
                    value > maxMinValues.max ? maxMinValues.maxMessage : ''}
            </ Help> : '')
    };

    const getMaxMinValuesInSqM = (source: ImaxMinValues) => {
        if (currentBuilding.displayUnit === 'ft2') {
            return source;
        }

        const conversionRate = 10.76391042;

        return {
            ...source,
            max: source.max / conversionRate,
            min: source.min / conversionRate,
        };
    };

    if (!formikBag.values.buildings[buildingIndex]) {
        const defaultValues: IBuilding = {
            id: '',
            siteId: '',
            bucket: '',
            shard: 'Building',
            eTag: '',
            clientFacilityId: '',
            clientCode: null,
            myDataPropertyId: null,
            createdDateTime: undefined,
            lastUpdatedDateTime: undefined,
            createdByPersonId: undefined,
            lastUpdatedByPersonId: undefined,
            buildingCode: '',
            buildingName: '',
            siteCode: '',
            country: '',
            facility: '',
            tier: '',
            city: '',
            county: '',
            latitude: undefined,
            region: '',
            longitude: undefined,
            isoCountryCode: '',
            addressLine1: '',
            addressLine2: '',
            isMasterAddress: undefined,
            areaCode: '',
            isActive: undefined,
            postCode: '',
            raisedFloorSpace_SqFt: null,
            totalSpace_SqFt: null,
            powerUsageEffectiveness: undefined,
            totalGeneratorPowerCapacity_MW: undefined,
            upsPowerCapacity_MW: undefined,
            itPowerCapacity_MW: undefined,
            whiteSpaceFeet: undefined,
            whitespaceMeters: undefined,
            displayUnit: 'ft2',
            questionnaireResult: 0,
            energyBills: [],
            weatherStationID: '',
            energyBillPeriods: 'month' as enumEnergyBillPeriods,
            currentFacilityItLoadMw: undefined,
            doYouHaveGeneratorsOrDRups: undefined,
            whatVoltageAreYourGeneratorsOrDrups: undefined,
            doYouHaveStaticUps: undefined,
            doesYourDataCentreHaveARaisedFloor: undefined,
            hotOrColdIsleContainment: undefined,
            anyExistingEnvironmentalMonitoring: undefined,
            anyExistingEnvironmentalMonitoringOther: undefined,
            isEnvironmentalMonitoringWorking: undefined,
            areYouMonitoringTemperaturePerRack: undefined,
            anyTemperatureAndOrHumiditySla: undefined,
            totalSiteCoolingPowerMw: undefined,
            coolingType: undefined,
            doYouHaveCracOrCrahs: undefined,
            numberOfCracOrCrahUnits: undefined,
            approximateTotalCoolingCapacityOfTheCracsOrCrahsMW: undefined,
            numberOfCracOrCrahUnitsOnStandbyOrOffByFault: undefined,
            doTheCracOrCrahUnitsHaveVariableSpeedFans: undefined,
            approximateAgeOfCracsOrCrahsInstallationYear: undefined,
            approximateAgeOfCoolingSystemInstallationYear: undefined,
            numberOfFloors: undefined,
            numberOfHalls: undefined,
            numberActiveItRacks: undefined,
        };
        formikBag.setFieldValue(`${prefix}`, defaultValues);
        return <></>;
    }

    const getCurrentEnergyBill = () => {
        const energyBillIndex: number = match.params.energyBillIndex
            ? parseInt(match.params.energyBillIndex, 10)
            : -1;
        // tslint:disable-next-line: max-line-length
        return currentBuilding.energyBills[energyBillIndex] !== undefined
            ? currentBuilding.energyBills[energyBillIndex]
            : defaultProps();
    };

    const defaultProps = (lastEnergyBill?: IEnergyBill) => {
        let defaultDate =
            currentBuilding.energyBillPeriods === enumEnergyBillPeriods.month
                ? moment(new Date()).startOf('month').utc().toDate()
                : moment(new Date()).startOf('week').add(1, 'days').utc().toDate();
        if (lastEnergyBill) {
            defaultDate = new Date(lastEnergyBill.date.toDateString());
            if (currentBuilding.energyBillPeriods === enumEnergyBillPeriods.month) {
                defaultDate.setMonth(defaultDate.getMonth() + 1);
                defaultDate.setDate(1);
            } else {
                defaultDate.setDate(defaultDate.getDate() + 7);
            }
        }
        return {
            id: '',
            electricityAmount: '0',
            itUsage: '0',
            date: defaultDate,
        };
    };
    const covertToNumber = (item: string) => {
        if (isNumber(item)) {
            return item;
        }
        return Number(item.replace(',', ''));
    };

    const points: Array<IKeyValue<number>> = currentBuilding.energyBills.map((data) => {
        return {
            key: moment(data.date).utc().format(),
            value: data.itUsage
                ? Math.round(
                    (covertToNumber(data.electricityAmount) / covertToNumber(data.itUsage)) * 100
                ) / 100
                : 0,
            label: moment(data.date).format('MMM YY'),
        };
    });

    const onChangeLatitude = (event: React.ChangeEvent<HTMLInputElement>): void =>
        formikBag.setFieldValue(`${prefix}.latitude`, !!event.target.value ? event.target.value : null);


    const onChangeLongitude = (event: React.ChangeEvent<HTMLInputElement>): void =>
        formikBag.setFieldValue(`${prefix}.longitude`, !!event.target.value ? event.target.value : null);

    const translateValue = (value, valueType) => {
        let result = value ?
            translate(`UpsertSite.Views.BuildingForm.${valueType}.` + value?.replace(/[^\w]/g, '')) :
            translate('OpportunityQ.MasterForm.EmptyOption');
        return result;
    }

    const handleChange = (field, value) => {
        formikBag.setFieldValue(field, value?.value);
    }

    if (match.params.energyBillIndex) {
        return (
            <EnergyBillForm
                energyBillType={currentBuilding.energyBillPeriods}
                energyBills={currentBuilding.energyBills}
                energyBill={getCurrentEnergyBill()}
                onSubmit={onSubmitEnergyBill}
            />
        );
    } else {
        const myDataPropertyId = formikBag.values.buildings[buildingIndex].myDataPropertyId;
        const myDataUpdateUrl = `${appConfig().REACT_APP_MYDATA_UPDATE_URL}/${myDataPropertyId}`;
        const isMyDataProperty = (): boolean => {
            return myDataPropertyId != null && myDataPropertyId !== '';
        };

        const getfacilityType = () => {
            let types = [];
            if (facilityType) {
                types = types.concat(facilityType.filter(
                    x => x.operatingPlatform === formikBag.values.operatingPlatform
                ));
            }
            types.push(getFacilityTypeAdditionalOptions())
            return types;
        };

        const getFacilityTypeAdditionalOptions = () => {
            return {
                operatingPlatform: '',
                operatingPlatformId: '',
                key: 'Other',
                value: 'Other',
                label: 'Other'
            };
        }
        return (
            <ArticlePanel className="column">
                <header>
                    <h1 className="title is-4">{translate('Romonet.Label.Faility')}</h1>
                    <small>{translate('UpsertSite.BuildingForm.Article.Text')}</small>
                </header>
                <Form>
                    <div className="FacilityForm">
                        <header className={!shouldPublish ? 'is-hidden' : null}>
                            <h2 className="title is-5">{'Facility #' + (buildingIndex + 1)}</h2>
                        </header>

                        {isMyDataProperty() && (
                            <p className='header-note'>
                                <strong>
                                    {translate('UpsertSite.Views.BuildingForm.Message2')} <a href={myDataUpdateUrl} target="_blank" rel="noopener noreferrer">{translate('UpsertSite.BuildingForm.Article.ClickHere')}</a> {translate('UpsertSite.Views.BuildingForm.Message3')}
                                </strong>
                            </p>
                        )}
                        <Field
                            isHorizontal={true}
                            htmlFor={field('buildingName')}
                            label={`${translate('UpsertSite.BuildingForm.Article.FacilityName')} #${buildingIndex + 1}`}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('buildingName')}
                                        name={field('buildingName')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Article.FacilityName')}
                                        className={getFieldCss('buildingName', 'input')}
                                        value={value('buildingName')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                    />
                                </Control>
                                {getHelpByKey('buildingName')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('clientFacilityId')}
                            label={translate('UpsertSite.BuildingForm.Field.ClientFacilityId')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('clientFacilityId')}
                                        name={field('clientFacilityId')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field. ClientFacilityId')}
                                        className={getFieldCss('clientFacilityId', 'input')}
                                        value={value('clientFacilityId')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                    />
                                </Control>
                                {getHelpByKey('clientFacilityId')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('addressLine1')}
                            label={translate('UpsertSite.BuildingForm.Field.Addressline1')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('addressLine1')}
                                        name={field('addressLine1')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Addressline1')}
                                        className={getFieldCss('addressLine1', 'input')}
                                        value={value('addressLine1')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                    />
                                </Control>
                                {getHelpByKey('addressLine1')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('addressLine2')}
                            label={translate('UpsertSite.BuildingForm.Field.Addressline2')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('addressLine2')}
                                        name={field('addressLine2')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Addressline2')}
                                        className={getFieldCss('addressLine2', 'input')}
                                        value={value('addressLine2')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                    />
                                </Control>
                                {getHelpByKey('addressLine2')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('county')}
                            label={translate('UpsertSite.BuildingForm.Field.StateCounty')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('county')}
                                        name={field('county')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.StateCounty')}
                                        className={getFieldCss('county', 'input')}
                                        value={value('county')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                    />
                                </Control>
                                {getHelpByKey('county')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('city')}
                            label={translate('LinkedBuildings.City')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('city')}
                                        name={field('city')}
                                        aria-required="true"
                                        placeholder={translate('LinkedBuildings.City')}
                                        className={getFieldCss('city', 'input')}
                                        value={value('city')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                    />
                                </Control>
                                {getHelpByKey('city')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('postCode')}
                            label={translate('UpsertSite.BuildingForm.Field.PostCode')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('postCode')}
                                        name={field('postCode')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.PostCode')}
                                        className={getFieldCss('postCode', 'input')}
                                        value={value('postCode')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                    />
                                </Control>
                                {getHelpByKey('postCode')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('region')}
                            label={translate('GainAccess.Labels.Regions')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    {!isMyDataField() &&
                                        <CustomSelect
                                            id={field('region')}
                                            name={field('region')}
                                            aria-required="true"
                                            options={lookupRegions}
                                            className={getFieldCss('region')}
                                            value={value('region')}
                                            onChange={formikBag.handleChange}
                                            onBlur={formikBag.handleBlur}
                                            emptyOptionsValue={translate('OpportunityQ.MasterForm.EmptyOption')}
                                        />
                                    }
                                    {isMyDataField() &&
                                        <input
                                            id={field('region')}
                                            name={field('region')}
                                            aria-required="true"
                                            placeholder={translate('GainAccess.Labels.Regions')}
                                            className={getFieldCss('region', 'input')}
                                            value={value('region')}
                                            disabled={true}
                                        />
                                    }
                                </Control>
                                {getHelpByKey('region')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('country')}
                            label={translate('LinkedBuildings.Country')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    {!isMyDataField() &&
                                        <CustomSelect
                                            id={field('country')}
                                            name={field('country')}
                                            aria-required="true"
                                            options={getCountriesByRegions()}
                                            className={getFieldCss('country')}
                                            value={value('country')}
                                            onChange={formikBag.handleChange}
                                            onBlur={formikBag.handleBlur}
                                            emptyOptionsValue={translate('OpportunityQ.MasterForm.EmptyOption')}
                                        />
                                    }
                                    {isMyDataField() &&
                                        <input
                                            id={field('country')}
                                            name={field('country')}
                                            aria-required="true"
                                            placeholder={translate('LinkedBuildings.Country')}
                                            className={getFieldCss('country', 'input')}
                                            value={value('country')}
                                            disabled={true}
                                        />
                                    }
                                </Control>
                                {getHelpByKey('country')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('latitude')}
                            label={translate('UpsertSite.BuildingForm.Field.Latitude')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('latitude')}
                                        name={field('latitude')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Latitude')}
                                        className={getFieldCss('latitude', 'input')}
                                        value={value('latitude')}
                                        onChange={onChangeLatitude}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                        type="number"
                                        max={90}
                                        min={-90}
                                    />
                                </Control>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('longitude')}
                            label={translate('UpsertSite.BuildingForm.Field.Longitude')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('longitude')}
                                        name={field('longitude')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Longitude')}
                                        className={getFieldCss('longitude', 'input')}
                                        value={value('longitude')}
                                        onChange={onChangeLongitude}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                        type="number"
                                        max={180}
                                        min={-180}
                                    />
                                </Control>
                            </Field>
                        </Field>
                        {checkPermission(Privilege.MyDataPropertyMappingToFacility, permissions) && (
                                <>
                                    <Field
                                        isHorizontal={true}
                                        htmlFor={field('myDataPropertyId')}
                                        label={translate('UpsertSite.BuildingForm.Field.MyDataPropertyId')}
                                        labelSize={BulmaSize.Medium}
                                    >
                                        <Field>
                                            <Control>
                                                <input
                                                    id={field('myDataPropertyId')}
                                                    name={field('myDataPropertyId')}
                                                    aria-required="true"
                                                    placeholder={translate('UpsertSite.BuildingForm.Field.MyDataPropertyId')}
                                                    className={getFieldCss('myDataPropertyId', 'input')}
                                                    value={value('myDataPropertyId')}
                                                    onBlur={formikBag.handleBlur}
                                                    type="text"
                                                    onChange={formikBag.handleChange}
                                                />
                                            </Control>
                                        </Field>
                                    </Field>
                                </>
                            )}
                        <Field
                            isHorizontal={true}
                            htmlFor={field('facility')}
                            label={translate('UpsertSite.BuildingForm.Field.FacilityType')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <CustomSelect
                                        id={field('facility')}
                                        name={field('facility')}
                                        aria-required="true"
                                        options={getfacilityType()}
                                        className={getFieldCss('facility', 'select')}
                                        value={value('facility')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate('OpportunityQ.MasterForm.EmptyOption')}
                                        disabled={isMyDataProperty()}
                                    />
                                    {getHelpByKey('facility')}
                                    {value('facility') === 'Other' && (
                                        <Control>
                                            <Input
                                                id={field('otherFacility')}
                                                name={field('otherFacility')}
                                                aria-required="true"
                                                placeholder={translate('UpsertSite.BuildingForm.Field.FacilityTypeSpecify')}
                                                className={getFieldCss('otherFacility', 'input')}
                                                value={value('otherFacility')}
                                                onChange={formikBag.handleChange}
                                                onBlur={(event) => formikBag.setFieldValue(event.target.name, event.target.value.trim())}
                                                isDisabled={isMyDataProperty()}
                                            />
                                        </Control>
                                    )}
                                    {getHelpByKey('otherFacility')}
                                </Control>
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('tier')}
                            label={translate('CermAssessmentSummary.Label.TierLevel')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <CustomSelect
                                        id={field('tier')}
                                        name={field('tier')}
                                        aria-required="true"
                                        options={lookupTierLevels}
                                        className={getFieldCss('tier', 'select')}
                                        value={value('tier')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        emptyOptionsValue={translate('OpportunityQ.MasterForm.EmptyOption')}
                                    />
                                </Control>
                                {getHelpByKey('tier')}
                            </Field>
                        </Field>

                        <br />
                        <FeatureToggle
                            feature={SiteFeatures.PUE}
                            features={formikBag.values.siteFeatures}
                        >
                            <Field
                                isHorizontal={true}
                                htmlFor={''}
                                label={translate('UpsertSite.BuildingForm.Field.Message')}
                                labelSize={BulmaSize.Medium}
                            />
                            <Field
                                isHorizontal={true}
                                htmlFor="questionnaire"
                                label={translate('UpsertSite.BuildingForm.Field.Message1')}
                                labelSize={BulmaSize.Medium}
                                infoText={translate('UpsertSite.BuildingForm.Field.Message2')}
                            >
                                <Field>
                                    {formikBag.values.buildings[buildingIndex].questionnaireResult >
                                        0 && (
                                            <span className="tag is-large">
                                                {
                                                    formikBag.values.buildings[buildingIndex]
                                                        .questionnaireResult
                                                }
                                            </span>
                                        )}
                                    <Button type="button" onClick={onNavigateToQuestionnaire}>
                                        {formikBag.values.buildings[buildingIndex]
                                            .questionnaireResult > 0
                                            ? translate('UpsertSite.BuildingForm.Button.ChangePueModel')
                                            : translate('UpsertSite.BuildingForm.Button.Launch')}
                                    </Button>
                                    {getHelpByKey('questionnaireResult')}
                                </Field>
                            </Field>
                            <Field
                                isHorizontal={true}
                                htmlFor={'monthlyEnergyBills'}
                                label={translate('UpsertSite.BuildingForm.Field.Message3')}
                                labelSize={BulmaSize.Medium}
                            >
                                <Field>
                                    <Control>
                                        <Radio
                                            id={field('energyBillPeriods')}
                                            name={field('energyBillPeriods')}
                                            options={monthlyEnergyBillsOptions}
                                            className={getFieldCss('energyBillPeriods')}
                                            value={getEnergyPeriod(value('energyBillPeriods'))}
                                            onBlur={formikBag.handleBlur}
                                            onChange={formikBag.handleChange}
                                            disabled={
                                                currentBuilding.energyBills &&
                                                currentBuilding.energyBills.length > 0
                                            }
                                        />
                                    </Control>
                                </Field>
                            </Field>
                            <br />
                            <Field
                                isHorizontal={true}
                                className="billing-table"
                                htmlFor="Electricity Usage"
                                label={translate('UpsertSite.BuildingForm.Article.PowerRatesTable')}
                                labelSize={BulmaSize.Medium}
                            >
                                <BillingTable
                                    enabled={!checkFormikBagIsValid()}
                                    energyBillPeriods={currentBuilding.energyBillPeriods}
                                    energyBills={currentBuilding.energyBills}
                                    onNavigateToEnergyBill={onNavigateToEnergyBill}
                                    onNavigateToEnergyBillDelete={onNavigateToEnergyBillDelete}
                                    onNavigateToEnergyBillEdit={onNavigateToEnergyBillEdit}
                                />
                            </Field>

                            <div id="chart" style={{ margin: '0 auto' }}>
                                {points && points.length > 1 && (
                                    <LineChart
                                        points={points}
                                        energyBillPeriod={currentBuilding.energyBillPeriods}
                                    />
                                )}
                                <br />
                                {currentBuilding.questionnaireResult > 0 &&
                                    currentBuilding.itPowerCapacity_MW > 0 &&
                                    pueProjections &&
                                    pueProjections !== null &&
                                    currentBuilding.energyBills && currentBuilding.energyBills.length > 0 && (
                                        <>
                                            <BarChartWithSelect
                                                pueProjections={pueProjections}
                                                points={points}
                                                energyBills={currentBuilding.energyBills}
                                                initialQuarter={getLatestQuarter(
                                                    currentBuilding.energyBills
                                                )}
                                                capacity={getCapacity()}
                                                energyBillPeriods={
                                                    currentBuilding.energyBillPeriods
                                                }
                                            />
                                        </>
                                    )}
                            </div>
                        </FeatureToggle>

                        <hr />
                        <div className="title">{translate('UpsertSite.BuildingForm.Field.BuildingData')}</div>

                        <Field
                            isHorizontal={true}
                            htmlFor="numberOfFloors"
                            label={translate('UpsertSite.BuildingForm.Field.NumberOfFloors')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('numberOfFloors')}
                                        type="number"
                                        name={field('numberOfFloors')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message4')}
                                        className={getFieldCss('numberOfFloors', 'input')}
                                        value={value('numberOfFloors')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('numberOfFloors')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="numberOfHalls"
                            label={translate('UpsertSite.BuildingForm.Field.NumberOfHalls')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('numberOfHalls')}
                                        type="number"
                                        name={field('numberOfHalls')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message5')}
                                        className={getFieldCss('numberOfHalls', 'input')}
                                        value={value('numberOfHalls')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('numberOfHalls')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="numberActiveItRacks"
                            label={translate('UpsertSite.BuildingForm.Field.NumberOfRacks')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('numberActiveItRacks')}
                                        type="number"
                                        name={field('numberActiveItRacks')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message6')}
                                        className={getFieldCss('numberActiveItRacks', 'input')}
                                        value={value('numberActiveItRacks')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('numberActiveItRacks')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="displayUnit"
                            label={translate('UpsertSite.BuildingForm.Field.Message7')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <FormSelect
                                        id={field('displayUnit')}
                                        name={field('displayUnit')}
                                        aria-required="true"
                                        options={unitOptions}
                                        className={getFieldCss('displayUnit', 'select')}
                                        value={value('displayUnit')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('displayUnit')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="raisedFloorSpace_SqFt"
                            label={`${translate('UpsertSite.BuildingForm.Field.RaisedFloor')} ${unitOptions.find((c) => c.key === value('displayUnit')).value}`}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('raisedFloorSpace_SqFt')}
                                        type="number"
                                        name={field('raisedFloorSpace_SqFt')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message8')}
                                        className={getFieldCss('raisedFloorSpace_SqFt', 'input')}
                                        value={value('raisedFloorSpace_SqFt')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpWarningByKey(
                                    'raisedFloorSpace_SqFt',
                                    getMaxMinValuesInSqM(maxMinValues.raisedFloorSpace_SqFt),
                                    value('raisedFloorSpace_SqFt')
                                )}
                                {getHelpByKey('raisedFloorSpace_SqFt')}

                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="totalSpace_SqFt"
                            label={`${translate('UpsertSite.BuildingForm.Field.TotalBuildingSpace')} ${unitOptions.find((c) => c.key === value('displayUnit')).value
                                }`}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        type="number"
                                        id={field('totalSpace_SqFt')}
                                        name={field('totalSpace_SqFt')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message9')}
                                        className={getFieldCss('totalSpace_SqFt', 'input')}
                                        value={value('totalSpace_SqFt')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                        disabled={isMyDataProperty()}
                                    />
                                </Control>
                                {getHelpByKey('totalSpace_SqFt')}
                                {getHelpWarningByKey(
                                    'totalSpace_SqFt',
                                    getMaxMinValuesInSqM(maxMinValues.totalSpace_SqFt),
                                    value('totalSpace_SqFt')
                                )}
                            </Field>
                        </Field>

                        <hr />
                        <div className="title">{translate('UpsertSite.Views.BuildingForm.Title')}</div>
                        <Field
                            isHorizontal={true}
                            htmlFor={field('doesYourDataCentreHaveARaisedFloor')}
                            label={translate('UpsertSite.BuildingForm.Field.Message10')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('doesYourDataCentreHaveARaisedFloor')}
                                        name={field('doesYourDataCentreHaveARaisedFloor')}
                                        options={yesNoOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.DoesYourDataCentreHaveARaisedFloor.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.doesYourDataCentreHaveARaisedFloor,
                                            value: formikBag?.values?.buildings[buildingIndex]?.doesYourDataCentreHaveARaisedFloor,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.doesYourDataCentreHaveARaisedFloor, "DoesYourDataCentreHaveARaisedFloor")
                                        }}
                                        onChange={(event) => handleChange(field('doesYourDataCentreHaveARaisedFloor'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('doesYourDataCentreHaveARaisedFloor')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor={field('hotOrColdIsleContainment')}
                            label={translate('UpsertSite.BuildingForm.Field.Message11')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('hotOrColdIsleContainment')}
                                        name={field('hotOrColdIsleContainment')}
                                        options={yesNoOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.HotOrColdIsleContainment.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.hotOrColdIsleContainment,
                                            value: formikBag?.values?.buildings[buildingIndex]?.hotOrColdIsleContainment,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.hotOrColdIsleContainment, "HotOrColdIsleContainment")
                                        }}
                                        onChange={(event) => handleChange(field('hotOrColdIsleContainment'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('hotOrColdIsleContainment')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor={field('anyExistingEnvironmentalMonitoring')}
                            label={translate('UpsertSite.BuildingForm.Field.Message12')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('anyExistingEnvironmentalMonitoring')}
                                        name={field('anyExistingEnvironmentalMonitoring')}
                                        options={environmentalMonitoringOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.AnyExistingEnvironmentalMonitoring.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.anyExistingEnvironmentalMonitoring,
                                            value: formikBag?.values?.buildings[buildingIndex]?.anyExistingEnvironmentalMonitoring,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.anyExistingEnvironmentalMonitoring, "AnyExistingEnvironmentalMonitoring")
                                        }}
                                        onChange={(event) => handleChange(field('anyExistingEnvironmentalMonitoring'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                    {value('anyExistingEnvironmentalMonitoring') === 'Other' && (
                                        <Control>
                                            <Input
                                                id={field(
                                                    'anyExistingEnvironmentalMonitoringOther'
                                                )}
                                                name={field(
                                                    'anyExistingEnvironmentalMonitoringOther'
                                                )}
                                                aria-required="true"
                                                placeholder={translate('UpsertSite.BuildingForm.Field.Message13')}
                                                className={getFieldCss(
                                                    'anyExistingEnvironmentalMonitoringOther',
                                                    'input'
                                                )}
                                                value={value(
                                                    'anyExistingEnvironmentalMonitoringOther'
                                                )}
                                                onChange={formikBag.handleChange}
                                                onBlur={formikBag.handleBlur}
                                            />
                                        </Control>
                                    )}
                                    {getHelpByKey('anyExistingEnvironmentalMonitoringOther')}
                                </Control>
                            </Field>
                        </Field>
                        {JSON.stringify(formikBag.error)}

                        <Field
                            isHorizontal={true}
                            htmlFor={field('isEnvironmentalMonitoringWorking')}
                            label={translate('UpsertSite.BuildingForm.Field.Message14')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('isEnvironmentalMonitoringWorking')}
                                        name={field('isEnvironmentalMonitoringWorking')}
                                        options={yesNoOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.IsEnvironmentalMonitoringWorking.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.isEnvironmentalMonitoringWorking,
                                            value: formikBag?.values?.buildings[buildingIndex]?.isEnvironmentalMonitoringWorking,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.isEnvironmentalMonitoringWorking, "IsEnvironmentalMonitoringWorking")
                                        }}
                                        onChange={(event) => handleChange(field('isEnvironmentalMonitoringWorking'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('isEnvironmentalMonitoringWorking')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor={field('areYouMonitoringTemperaturePerRack')}
                            label={translate('UpsertSite.BuildingForm.Field.Message41')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('areYouMonitoringTemperaturePerRack')}
                                        name={field('areYouMonitoringTemperaturePerRack')}
                                        options={yesNoOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.AreYouMonitoringTemperaturePerRack.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.areYouMonitoringTemperaturePerRack,
                                            value: formikBag?.values?.buildings[buildingIndex]?.areYouMonitoringTemperaturePerRack,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.areYouMonitoringTemperaturePerRack, "AreYouMonitoringTemperaturePerRack")
                                        }}
                                        onChange={(event) => handleChange(field('areYouMonitoringTemperaturePerRack'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('areYouMonitoringTemperaturePerRack')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor={field('anyTemperatureAndOrHumiditySla')}
                            label={translate('UpsertSite.BuildingForm.Field.Message15')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('anyTemperatureAndOrHumiditySla')}
                                        name={field('anyTemperatureAndOrHumiditySla')}
                                        options={yesNoOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.AnyTemperatureAndOrHumiditySla.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.anyTemperatureAndOrHumiditySla,
                                            value: formikBag?.values?.buildings[buildingIndex]?.anyTemperatureAndOrHumiditySla,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.anyTemperatureAndOrHumiditySla, "AnyTemperatureAndOrHumiditySla")
                                        }}
                                        onChange={(event) => handleChange(field('anyTemperatureAndOrHumiditySla'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('anyTemperatureAndOrHumiditySla')}
                            </Field>
                        </Field>
                        <Field
                            isHorizontal={true}
                            htmlFor="totalSiteCoolingPowerMw"
                            label={translate('UpsertSite.BuildingForm.Field.Message16')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('totalSiteCoolingPowerMw')}
                                        name={field('totalSiteCoolingPowerMw')}
                                        aria-required="true"
                                        type="number"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message17')}
                                        className={getFieldCss('totalSiteCoolingPowerMw', 'input')}
                                        value={value('totalSiteCoolingPowerMw')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('totalSiteCoolingPowerMw')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor={field('coolingType')}
                            label={translate('UpsertSite.BuildingForm.Field.Message18')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('coolingType')}
                                        name={field('coolingType')}
                                        options={coolingTypeOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.CoolingType.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.coolingType,
                                            value: formikBag?.values?.buildings[buildingIndex]?.coolingType,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.coolingType, "CoolingType")
                                        }}
                                        onChange={(event) => handleChange(field('coolingType'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('coolingType')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor={field('doYouHaveCracOrCrahs')}
                            label={translate('UpsertSite.BuildingForm.Field.Message19')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('doYouHaveCracOrCrahs')}
                                        name={field('doYouHaveCracOrCrahs')}
                                        options={yesNoOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.DoYouHaveCracOrCrahs.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.doYouHaveCracOrCrahs,
                                            value: formikBag?.values?.buildings[buildingIndex]?.doYouHaveCracOrCrahs,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.doYouHaveCracOrCrahs, "DoYouHaveCracOrCrahs")
                                        }}
                                        onChange={(event) => handleChange(field('doYouHaveCracOrCrahs'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('doYouHaveCracOrCrahs')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="numberOfCracOrCrahUnits"
                            label={translate('UpsertSite.BuildingForm.Field.Message20')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('numberOfCracOrCrahUnits')}
                                        name={field('numberOfCracOrCrahUnits')}
                                        type="number"
                                        className={getFieldCss('numberOfCracOrCrahUnits', 'input')}
                                        value={value('numberOfCracOrCrahUnits')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('numberOfCracOrCrahUnits')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="approximateTotalCoolingCapacityOfTheCracsOrCrahsMW"
                            label={translate('UpsertSite.BuildingForm.Field.Message21')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field(
                                            'approximateTotalCoolingCapacityOfTheCracsOrCrahsMW'
                                        )}
                                        name={field(
                                            'approximateTotalCoolingCapacityOfTheCracsOrCrahsMW'
                                        )}
                                        type="number"
                                        className={getFieldCss(
                                            'approximateTotalCoolingCapacityOfTheCracsOrCrahsMW',
                                            'input'
                                        )}
                                        value={value(
                                            'approximateTotalCoolingCapacityOfTheCracsOrCrahsMW'
                                        )}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('approximateTotalCoolingCapacityOfTheCracsOrCrahsMW')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="numberOfCracOrCrahUnitsOnStandbyOrOffByFault"
                            label={translate('UpsertSite.BuildingForm.Field.Message22')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('numberOfCracOrCrahUnitsOnStandbyOrOffByFault')}
                                        name={field('numberOfCracOrCrahUnitsOnStandbyOrOffByFault')}
                                        type="number"
                                        className={getFieldCss(
                                            'numberOfCracOrCrahUnitsOnStandbyOrOffByFault',
                                            'input'
                                        )}
                                        value={value(
                                            'numberOfCracOrCrahUnitsOnStandbyOrOffByFault'
                                        )}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('numberOfCracOrCrahUnitsOnStandbyOrOffByFault')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor={field('doTheCracOrCrahUnitsHaveVariableSpeedFans')}
                            label={translate('UpsertSite.BuildingForm.Field.Message23')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('doTheCracOrCrahUnitsHaveVariableSpeedFans')}
                                        name={field('doTheCracOrCrahUnitsHaveVariableSpeedFans')}
                                        options={yesNoOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.DoTheCracOrCrahUnitsHaveVariableSpeedFans.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.doTheCracOrCrahUnitsHaveVariableSpeedFans,
                                            value: formikBag?.values?.buildings[buildingIndex]?.doTheCracOrCrahUnitsHaveVariableSpeedFans,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.doTheCracOrCrahUnitsHaveVariableSpeedFans, "DoTheCracOrCrahUnitsHaveVariableSpeedFans")
                                        }}
                                        onChange={(event) => handleChange(field('doTheCracOrCrahUnitsHaveVariableSpeedFans'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('doTheCracOrCrahUnitsHaveVariableSpeedFans')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="approximateAgeOfCracsOrCrahsInstallationYear"
                            label={translate('UpsertSite.BuildingForm.Field.Message24')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('approximateAgeOfCracsOrCrahsInstallationYear')}
                                        name={field('approximateAgeOfCracsOrCrahsInstallationYear')}
                                        type="number"
                                        className={getFieldCss(
                                            'approximateAgeOfCracsOrCrahsInstallationYear',
                                            'input'
                                        )}
                                        value={value(
                                            'approximateAgeOfCracsOrCrahsInstallationYear'
                                        )}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('approximateAgeOfCracsOrCrahsInstallationYear')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="approximateAgeOfCoolingSystemInstallationYear"
                            label={translate('UpsertSite.BuildingForm.Field.Message25')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('approximateAgeOfCoolingSystemInstallationYear')}
                                        name={field(
                                            'approximateAgeOfCoolingSystemInstallationYear'
                                        )}
                                        type="number"
                                        className={getFieldCss(
                                            'approximateAgeOfCoolingSystemInstallationYear',
                                            'input'
                                        )}
                                        value={value(
                                            'approximateAgeOfCoolingSystemInstallationYear'
                                        )}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('approximateAgeOfCoolingSystemInstallationYear')}
                            </Field>
                        </Field>

                        <hr />
                        <div className="title">{translate('UpsertSite.BuildingForm.Field.ElectricalData')}</div>

                        <Field
                            isHorizontal={true}
                            htmlFor="totalGeneratorPowerCapacity_MW"
                            label={translate('UpsertSite.BuildingForm.Field.Message27')}
                            labelSize={BulmaSize.Medium}
                            infoText={translate('UpsertSite.BuildingForm.Field.Message27')}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('totalGeneratorPowerCapacity_MW')}
                                        name={field('totalGeneratorPowerCapacity_MW')}
                                        aria-required="true"
                                        type="number"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message28')}
                                        className={getFieldCss(
                                            'totalGeneratorPowerCapacity_MW',
                                            'input'
                                        )}
                                        value={value('totalGeneratorPowerCapacity_MW')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('totalGeneratorPowerCapacity_MW')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="upsPowerCapacity_MW"
                            label={translate('UpsertSite.BuildingForm.Field.Message29')}
                            labelSize={BulmaSize.Medium}
                            infoText={translate('UpsertSite.BuildingForm.Field.Message30')}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('upsPowerCapacity_MW')}
                                        name={field('upsPowerCapacity_MW')}
                                        aria-required="true"
                                        type="number"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message31')}
                                        className={getFieldCss('upsPowerCapacity_MW', 'input')}
                                        value={value('upsPowerCapacity_MW')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('upsPowerCapacity_MW')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="itPowerCapacity_MW"
                            label={translate('UpsertSite.BuildingForm.Field.Message32')}
                            labelSize={BulmaSize.Medium}
                            infoText={translate('UpsertSite.BuildingForm.Field.Message33')}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('itPowerCapacity_MW')}
                                        name={field('itPowerCapacity_MW')}
                                        aria-required="true"
                                        type="number"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message34')}
                                        className={getFieldCss('itPowerCapacity_MW', 'input')}
                                        value={value('itPowerCapacity_MW')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>

                                {getHelpWarningByKey('itPowerCapacity_MW', maxMinValues['itPowerCapacity_MW'], value('itPowerCapacity_MW'))}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="currentFacilityItLoadMw"
                            label={translate('UpsertSite.BuildingForm.Field.CurrentFacilityITLoad')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('currentFacilityItLoadMw')}
                                        name={field('currentFacilityItLoadMw')}
                                        type="number"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message35')}
                                        className={getFieldCss('currentFacilityItLoadMw', 'input')}
                                        value={value('currentFacilityItLoadMw')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('currentFacilityItLoadMw')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="powerUsageEffectiveness"
                            label={translate('UpsertSite.BuildingForm.Field.FacilityPowerUsageEffectiveness')}
                            labelSize={BulmaSize.Medium}
                            infoText={translate('UpsertSite.BuildingForm.Field.TotalActualFacilityLoad')}
                        >
                            <Field>
                                <Control>
                                    <input
                                        type="number"
                                        id={field('powerUsageEffectiveness')}
                                        name={field('powerUsageEffectiveness')}
                                        aria-required="true"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message36')}
                                        className={getFieldCss('powerUsageEffectiveness', 'input')}
                                        value={value('powerUsageEffectiveness')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('powerUsageEffectiveness')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="doYouHaveGeneratorsOrDRups"
                            label={translate('UpsertSite.BuildingForm.Field.Message37')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('doYouHaveGeneratorsOrDRups')}
                                        name={field('doYouHaveGeneratorsOrDRups')}
                                        options={doYouHaveGeneratorsOrDRupsOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.DoYouHaveGeneratorsOrDrups.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.doYouHaveGeneratorsOrDRups,
                                            value: formikBag?.values?.buildings[buildingIndex]?.doYouHaveGeneratorsOrDRups,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.doYouHaveGeneratorsOrDRups, "DoYouHaveGeneratorsOrDrups")
                                        }}
                                        onChange={(event) => handleChange(field('doYouHaveGeneratorsOrDRups'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('doYouHaveGeneratorsOrDRups')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="whatVoltageAreYourGeneratorsOrDrups"
                            label={translate('UpsertSite.BuildingForm.Field.Message38')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <input
                                        id={field('whatVoltageAreYourGeneratorsOrDrups')}
                                        name={field('whatVoltageAreYourGeneratorsOrDrups')}
                                        type="number"
                                        placeholder={translate('UpsertSite.BuildingForm.Field.Message39')}
                                        className={getFieldCss(
                                            'whatVoltageAreYourGeneratorsOrDrups',
                                            'input'
                                        )}
                                        value={value('whatVoltageAreYourGeneratorsOrDrups')}
                                        onChange={formikBag.handleChange}
                                        onBlur={formikBag.handleBlur}
                                    />
                                </Control>
                                {getHelpByKey('whatVoltageAreYourGeneratorsOrDrups')}
                            </Field>
                        </Field>

                        <Field
                            isHorizontal={true}
                            htmlFor="doYouHaveStaticUps"
                            label={translate('UpsertSite.BuildingForm.Field.Message40')}
                            labelSize={BulmaSize.Medium}
                        >
                            <Field>
                                <Control>
                                    <Select
                                        id={field('doYouHaveStaticUps')}
                                        name={field('doYouHaveStaticUps')}
                                        options={yesNoOptions.map((c) => ({
                                            key: c.key,
                                            label: translate(
                                                'UpsertSite.Views.BuildingForm.DoYouHaveStaticUps.'.concat(
                                                    c.value?.replace(/[^\w]/g, '')
                                                )
                                            ),
                                            value: c.value,
                                        }))}
                                        value={{
                                            key: formikBag?.values?.buildings[buildingIndex]?.doYouHaveStaticUps,
                                            value: formikBag?.values?.buildings[buildingIndex]?.doYouHaveStaticUps,
                                            label: translateValue(formikBag?.values?.buildings[buildingIndex]?.doYouHaveStaticUps, "DoYouHaveStaticUps")
                                        }}
                                        onChange={(event) => handleChange(field('doYouHaveStaticUps'), event)}
                                        noOptionsMessage={() => translate(
                                            'OpportunityQ.MasterForm.EmptyOption'
                                        )}
                                    />
                                </Control>
                                {getHelpByKey('doYouHaveStaticUps')}
                            </Field>
                        </Field>

                        <div className="action-groups">
                            <div className="action-group">
                                <Button onClick={onNavigateToPreviousFacility}>{translate('Globals.Label.Back')}</Button>
                                {formikBag.values.buildings &&
                                    formikBag.values.buildings.length > 0 &&
                                    checkPermission('BuildingsDelete', permissions) && (
                                        <>
                                            <Button
                                                buttonType="archive"
                                                onClick={onDeleteBuildingHandler}
                                            >
                                                {translate('Attachments.AttachmentList.ButtonWithConfirmDialog.ButtonText')}
                                            </Button>
                                            <BuildingArchiveConfirmation
                                                backLinkUrl={backLinkUrl}
                                                confirmMessage={`Are you sure you want to delete this building?`}
                                            />
                                        </>
                                    )}
                            </div>
                            <div className="action-group">
                                <Button
                                    disabled={buildingIndex === formikBag.values.buildings.length - 1}
                                    onClick={onNavigateToNextFacility}
                                >
                                    {translate('SchedulerWeek.Button.NextDay.buttonText')}
                                </Button>
                                <Button onClick={onAddFacility}
                                >
                                    {translate('UpsertSite.BuildingForm.Field.AddFacility')}
                                </Button>
                                <FloatingButton
                                    onClick={onPublish}
                                    isLoading={isSubmitting}
                                    float={formikBag.dirty}
                                    tooltip={translate('Globals.Label.PleaseClick')}
                                >
                                    {translate('Globals.Label.Save')}
                                </FloatingButton>
                            </div>
                        </div>
                    </div>
                </Form>
            </ArticlePanel >
        );
    }
};
